@import '_variables';
@import '_mediaQueries';

.MnAr--Carousel {
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  --slide-height: 299px;
  --slide-spacing: 67px;
  --slide-size: 33.33%;

  .carouselViewport {
    max-width: 938px;
    margin: auto;
    overflow: hidden;
  }

  .carouselContainer {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
  }

  .carouselSlide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }

  .reviewCard {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 6px;
    height: 100%;
    min-height: var(--slide-height);
    background-color: white;
    padding: 48px 38px 32px;

    &__initial {
      width: 51px;
      height: 51px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 29px;
      color: white;
    }

    &__name {
      margin-top: 26px;
      font-size: 18px;
      font-weight: bold;
    }

    &__review {
      font-size: 14px;
    }
  }

  .carouselButton {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      cursor: initial;
    }

    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }
  }

  .carouselDots {
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;

      // desktop first
      display: none;
    }

    &__dot {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      touch-action: manipulation;
      display: inline-flex;
      text-decoration: none;
      cursor: pointer;
      border: 0;
      padding: 0;
      margin: 0;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &::after {
        background-color: #d9d9d9;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
      }

      &.selected::after {
        background-color: #9da0a4;
      }
    }
  }

  @include media-lg-and-down {
    --slide-spacing: 40px;

    .carouselViewport {
      max-width: 884px;
    }
  }

  @include media-md-and-down {
    --slide-height: 266px;
    --slide-spacing: 20px;

    .carouselViewport {
      max-width: 670px;
    }

    .reviewCard {
      padding: 42px 34px 34px;
      &__name {
        margin-top: 18px;
      }
    }
  }

  @include media-sm-and-down {
    --slide-size: 360px;
    --slide-spacing: 100px;

    .carouselViewport {
      max-width: 100%;
    }

    .carouselButton {
      display: none;
    }

    .carouselDots {
      &__container {
        display: flex;
      }
    }
  }
}
