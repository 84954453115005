@import '_variables';
@import '_buttons';
@import '_utils';
@import '_mediaQueries';
@import '_fontFace';
@import '_carousel';
@import '_faq--section';

@include font-face('circular', 'circularStd/CircularStd-Book', 500);
@include font-face('circular', 'circularStd/CircularStd-Bold', 800);
@include font-face('klein', 'klein/KleinCondensed-ExtraBold', 800);

.card {
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(44, 46, 48, 0.05);
  border-radius: 0.5rem;
  padding: 1rem;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  color: $text-color;
  font-family: 'circular', sans-serif;
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
}

.bootstrap-wrapper {
  .container.custom {
    max-width: 814px;
  }
}

.MnAr--Header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.84;
  z-index: 100;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn {
    margin: 1rem;
  }
  .btn.signup {
    margin: 1rem 0;
    border-radius: 11.21px;
    min-width: 13rem;
  }
  .logo-moni {
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
    img {
      max-height: 40px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    z-index: 1;

    @include media-sm-and-down {
      gap: 16px;
    }

    @include media-xxs-and-down {
      gap: 8px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #e7eff4, #fff);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &.sticky {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 2;
    transition: background 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    .logo-moni {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
    &:before {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }

  @include media-md-and-down {
    position: initial;
    .btn {
      margin: 0;
    }
    .btn.signup {
      background-color: #fff;
      color: $primary-color;
      min-width: 6.375rem;
      min-height: 2.625rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      font-weight: 800;
      border-radius: 21px;
    }
  }
}

.MnAr--Heading {
  font-size: 2rem;
  color: $text-color;
  font-family: 'klein', sans-serif;
  text-transform: uppercase;
  &.primary {
    color: $primary-color;
  }
  &.dark {
    color: $dark-blue;
  }
  &.dark-gray {
    color: $dark-gray;
  }
  &.white {
    color: #fff;
  }

  @include media-sm-and-down {
    font-size: 1.5rem;
  }
}

.MnAr--Subheading {
  display: flex;
  font-size: 1.25rem;
  color: $text-color;
  justify-content: center;
  &.primary {
    color: $primary-color;
  }
  &.dark {
    color: $dark-blue;
  }
  &.white {
    color: #fff;
  }
  @include media-sm-and-down {
    font-size: 1.125rem !important;
  }
}

.MnAr--SectionTitle {
  color: $light-gray;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  padding: 0.5rem 0;
  opacity: 0.6;
  span {
    margin-left: 0.5rem;
  }
}

.MnAr--FAQ {
  .containerCustom {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'imgContent questionsContent';

    .imgContent {
      grid-area: imgContent;
    }
    .questionsContent {
      grid-area: questionsContent;
    }
    @include media-md-and-down {
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 0.5fr;
      grid-template-areas: 'imgContent' 'questionsContent';
    }
  }
}

.MnAr--PreFooter {
  background-color: $bg-light-blue;
  padding: 130px 0 120px;

  .container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 120px;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;

    &__description {
      flex-grow: 1;
      background-image: url(../img/bg-prefooter-deco.svg);
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 283px;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        font-family: 'klein', Sans-serif;
        text-transform: uppercase;
        font-size: 48px;

        b {
          color: $stormy-blue;
        }
      }
    }
  }

  @include media-sm-and-down {
    padding: 96px 0 60px;

    .heading {
      flex-direction: column;
      gap: 10px;

      &__description {
        background: none;
        text-align: center;
      }
    }
  }
}

.MnAr--Footer {
  background-color: $darkest-blue;
  font-size: 14px;
  .container {
    max-width: 986px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .footer__links {
      padding: 0 0 1rem;
      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 10px 10px;
        grid-template-areas: 'blog tyc help';
        margin-bottom: 40px;

        &-blog {
          grid-area: blog;
        }
        &-tyc {
          grid-area: tyc;
        }
        &-help {
          grid-area: help;
        }

        &-title {
          display: flex;
          color: white;
          font-size: 21px;
          border-bottom: 1px solid #5574a5;
          margin-bottom: 10px;

          img {
            margin-right: 10px;
            margin-bottom: 5px;
            position: relative;
            bottom: 5px;
          }
        }

        &-link,
        &-button {
          color: rgba(255, 255, 255, 0.447662);
          font-size: 15px;
          text-decoration: underline;
          text-align: start;
        }

        &-button {
          background-color: transparent;
          border: none;
          padding: 0;
          cursor: pointer;
        }

        @media (max-width: $break-small) {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            justify-content: center;
          }

          &-button {
            text-align: center;
          }
        }
      }
    }
    .footer__logo-moni {
      width: 100px;
      @media (max-width: $break-small) {
        display: flex;
        align-self: center;
      }
    }
    .footer__initial {
      padding: 2rem 0 2.5rem;
      &-txt {
        color: $primary-color;
        font-size: 36px;
      }
      @media (max-width: $break-small) {
        display: flex;
        flex-direction: column;
      }
    }

    .footer__buttons {
      &-signup {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 8px;

        background-color: #4193ee;
        background-image: url('/static/img/bg-button.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;

        border-radius: 24px;
        margin-top: 20px;
        padding: 54px 32px;

        &-text {
          font-size: 36px;
          color: #f0f7fa;

          b {
            color: #324b6f;
          }
        }

        &-button {
          margin: 0 !important;
          flex-shrink: 0;
          background-color: #e8fbfa;
          color: #324b6f;
          font-weight: 400;
        }

        @media (max-width: $break-small) {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 10px;
          padding: 32px 16px;

          &-text {
            font-size: 28px;
          }
        }
      }

      &-legal {
        margin-top: 48px;
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 16px;

        &-button {
          flex: 1 1 auto;
          padding: 24px 16px;
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;

          button {
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
            font-weight: bold;
            font-size: 28px;
            text-align: center;
            text-decoration-line: underline;
          }

          span {
            font-size: 18px;
            text-align: center;
            text-decoration-line: none;
            color: #5493e7;
          }

          &.regret {
            background-color: #d6edec;
            button {
              color: #1dc086;
            }
          }

          &.unsubscribe {
            background-color: #d5cff3;
            button {
              color: #9da0a4;
            }
          }
        }

        @media (max-width: $break-small) {
          flex-direction: column;
          margin-top: 32px;

          a {
            font-size: 22px;
          }

          span {
            font-size: 14px;
          }
        }
      }

      &-media {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 32px 0;

        &-text {
          margin: 0;
          color: #f0f7fa;
          text-align: center;
          font-size: 21px;
        }

        &-logos {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 42px;
        }

        @media (max-width: $break-small) {
          &-logos {
            gap: 24px;
          }

          &-text {
            font-size: 18px;
          }
        }
      }
    }

    .footer__legal {
      padding: 2rem 0 2.5rem;
      border-top: 1px solid rgba(215, 215, 215, 0.26);

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @media (max-width: $break-small) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 32px;
        }
      }

      &-txt {
        color: $primary-color;
        opacity: 0.6;
        text-align: justify;
      }

      &-images {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        a {
          display: inline-flex;
          color: $primary-color;
          text-decoration: underline;
        }

        @media (max-width: $break-small) {
          margin-top: 12px;
        }
      }

      &-cft {
        color: #fff;
        opacity: 0.5;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0;
      }
    }
    .btn {
      margin: 0px 2.25rem;
      color: #324b6f;
    }

    @include media-lg-and-down {
      .btn {
        margin-bottom: 10px;
      }
    }

    @include media-sm-and-down {
      text-align: center;
      .btn {
        display: block;
        text-align: center;
        margin: 1rem auto;
        padding: 1rem;
        max-width: 12rem;
      }
      .footer__links {
        &-logo {
          width: 48px;
          height: 48px;
          margin: 0.5rem;
        }
      }
    }
  }
}
