//variables

$primary-color: #5493e7;

$medium-blue: #233857;

$dark-blue: #324b6f;

$darkest-blue: #132338;

$bg-light-blue: #f0f7fa;

$light-blue: #dde8ee;

$light-gray: #c1cdd4;

$disabled-gray: #e5e5e5;

$dark-gray: #898989;

$bg-light-green: #f3fbf9;

$bg-mid-green: #d5f6eb;

$bg-green: #1cc589;

$text-color: #a0b4be;

$primary-gradient: linear-gradient(to bottom, #d8cff5, #a9cddc 50%, #81cbc7);

$accent-orange: #f99f00;

$stormy-blue: #5574a5;

$lavander: #d5cff3;

$yellow: #ffee00;

$light-green: #82cac6;

$dark-blue: #385c8c;

$mid-gray: #98aab4;

$bg-light-gray: rgba(215, 228, 244, 0.35);

$primary-redAlert: #ff6363;
