.MnAr--FAQ {
  background: $bg-light-blue;
  color: $text-color;
  padding: 3rem 0;

  .faq {
    &--heading {
      font-family: 'Klein', Arial, sans-serif;
      font-size: 3em;
      line-height: 1em;
      color: $text-color;
      text-transform: uppercase;
      margin: 0.4em 0 0.5em 0;
    }
    &--text {
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;
      margin: 0 0 2em 0;
    }
    &__container {
      background-color: $bg-light-blue;
    }
    &--block {
      border-bottom: 1px solid $bg-light-blue;
      &.open {
        .faq--question::after {
          transform: none;
        }
        .faq--answer {
          display: block;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    &--question {
      color: $mid-gray;
      font-size: 1.12rem;
      font-weight: 800;
      position: relative;
      cursor: pointer;
      padding-right: 1rem;
      &::after {
        content: url('../img/icon-arrow.svg');
        display: block;
        position: absolute;
        top: 2px;
        right: 0;
        transform: rotate(180deg);
      }
    }
    &--answer {
      display: none;
      font-size: 14px;
      line-height: 18px;

      a {
        color: $text-color;
        text-decoration: underline;
      }
    }
  }

  @include media-md-and-down {
    .faq--question {
      font-size: 0.93rem;
      line-height: 19px;
    }
    .faq--answer {
      font-size: 0.87rem;
      line-height: 18px;
    }
    .faq--text {
      font-size: 1.12rem;
      line-height: 23px;
      text-align: center;
    }
    .faq--heading {
      text-align: center;
      font-size: 1.87rem;
      line-height: 31px;
    }
  }
}
