@import "variables";

.text-center{
	text-align: center;
}

.text-left{
	text-align: left;
}

.text-right{
	text-align: right;
}

.align-items-center{
	align-items: center;
}

.justify-content-center{
	justify-content: center;
}

.justify-content-between{
	justify-content: space-between;
}

.flex-nowrap{
	flex-wrap: nowrap !important;
}

.margin-auto{
	margin: auto;
}

.margin-auto-0{
	margin: auto 0;
}

.margin-0-auto{
	margin: auto 0;
}

.text-lavander{
	color: $lavander;
}

.text-green{
	color: $bg-green;
}

.text-yellow{
	color: $yellow;
}

.text-darkblue{
	color: $dark-blue;
}

$size:('xs':'screen and (max-width:479px)',
	'sm':'screen and (min-width:480px) and (max-width:767px)',
	'md':'screen and (min-width:768px) and (max-width:989px)',
	'lg':'screen and (min-width:990px)'
);

@each $col,
$query in $size {
	@media #{$query} {
		.hidden-#{$col} {
			display: none !important;
		}

		.visible-#{$col}-inline {
			display: inline !important;
		}

		.visible-#{$col}-inline-block {
			display: inline-block !important;
		}

		.visible-#{$col}-block {
			display: block !important;
		}
	}
}

$visibility-sizes:('xs':480px,
	'sm':768px,
	'md':990px);



@each $col,
$query in $visibility-sizes {
	@media screen and (max-width: #{$query - 1px}) {
		.hidden-#{$col}-and-down {
			display: none !important;
		}

		.visible-#{$col}-and-down {
			display: block !important;
		}
	}
}

@each $col,
$query in $visibility-sizes {
	@media screen and (min-width: #{$query}) {
		.hidden-#{$col}-and-up {
			display: none !important;
		}

		.visible-#{$col}-and-up {
			display: block !important;
		}
	}
}
