@import "_mediaQueries";

.btn {
  display: inline-flex;
  padding: 1rem;
  min-width: 20rem;
  min-height: 3.375rem;
  background-color: $primary-color;
  color: #fff;
  border-radius: 2rem;
  text-decoration: none;
  font-size: 16px;
  margin: 2rem 0;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  &:hover {
    background-color: lighten($primary-color, 5%);
  }

  &.subtle {
    color: $primary-color;
    background-color: transparent;
    min-width: initial;
    min-height: initial;
    font-weight: 800;
    margin: 0 0.5rem;
  }

  &.white {
    color: $primary-color;
    background-color: #fff;
    min-width: initial;
    min-height: initial;
    font-weight: 800;
    margin: 0 0.5rem;
  }

  &.yellow {
    background-color: $yellow;
    color: #5f6f64;
  }

  &.bordered {
    background-color: transparent;
    border: 2px solid #fff;
    &:hover {
      background-color: #4193ee;
      color: white;
      //   border-color: rgba(255, 255, 255, 0.5);
      //   color: rgba(255, 255, 255, 0.5);
    }
  }

  &.btn_short {
    min-width: 16rem;
    margin: 10px 0;
  }

  &.sm {
    font-size: 0.75rem;
    min-width: 11.25rem;
    padding: 0.5rem;
    min-height: initial;
  }

  &.signup {
    background-color: #fff;
    color: $primary-color;
    min-width: 9.375rem;
    min-height: 2.625rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-weight: 800;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }

  @include media-sm-and-down {
    min-width: 18rem;
  }

  // @include media-lg-and-down{

  // 	&.bordered{
  // 		margin:0 0 10px 0;
  // 		&.sm{
  // 			font-size: 0.65rem;
  // 		}
  // 	}

  // }
}
