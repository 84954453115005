@mixin font-face($style-name, $file, $weight:normal, $style:normal) {
    $filepath: "../fonts/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
             url($filepath + ".otf") format('opentype'),
             url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}