@import 'MnAr--NewLandingBase';

.webp {
  .MnAr--Hero {
    background: url('../img/bg-mobiles.svg') no-repeat, #bfd8f8;

    @include media-sm-and-down {
      &:after {
        background: url('../img/bg-mobiles-responsive.svg') no-repeat;
        background-size: 100%;
        background-position-x: center;
        background-position-y: bottom;
      }
    }
  }
}

.no-webp {
  .MnAr--Hero {
    background: url('../img/jpgpng/moni-hero.png') no-repeat,
      url('../img/bg-hero.svg') no-repeat, url('../img/bg-hero-02.svg') repeat-y,
      $primary-gradient;

    &:after {
      background: url('../img/jpgpng/moni-hero.png') no-repeat;
    }

    @include media-sm-and-down {
      &:after {
        background: url('../img/jpgpng/moni-hero-mobile.png') no-repeat;
      }
    }
  }
}

.webp,
.no-webp {
  .MnAr--Hero {
    background-position: left, left calc(50% + 300px) top 90%, right;
    background-size: auto, auto, 60%, auto;
    background-blend-mode: normal, screen;
    color: #fff;
    min-height: 41.2rem;
    position: relative;
    overflow: hidden;
    .content {
      display: block;
      padding: 28vh 0 0 0;
      float: right;

      .hero__txt {
        @media (max-width: $break-small) {
          display: flex;
          position: absolute;
          justify-content: center;
          right: 0;
          left: 0;
          top: 9rem;
          z-index: 1;
        }
      }

      .hero__Subheading {
        @media (max-width: $break-small) {
          padding: 1rem 0 0 0;
          display: flex;
          position: absolute;
          justify-content: center;
          right: 0;
          left: 0;
          bottom: -1rem;
          z-index: 1;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
    }

    @include media-sm-and-down {
      min-height: 40rem;
      background: #bfd8f8;
      &:after {
        width: 100%;
        min-width: 340px;
        height: 100%;
      }
    }
  }

  .MnAr--Features {
    background: url('../img/bg-spot.svg') no-repeat, $bg-light-gray;
    background-position: top right;
    background-size: 500px;
    padding: 2.875rem 0 2.5rem;
    display: grid;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: 0.1fr 0.1fr 0.1fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr;
    grid-template-areas:
      'heading heading heading'
      'iconLoan iconWallet iconQr'
      'iconReload iconSube iconBills';

    .title {
      grid-area: heading;
      .headingTitle {
        color: #76a9ec;
        font-size: 64px;
      }
    }

    .iconLoan {
      grid-area: iconLoan;
    }

    .iconWallet {
      grid-area: iconWallet;
    }

    .iconQr {
      grid-area: iconQr;
    }

    .iconReload {
      grid-area: iconReload;
    }

    .iconSube {
      grid-area: iconSube;
    }

    .iconBills {
      grid-area: iconBills;
    }
    .feature {
      &__img {
        max-height: 200px;
      }
    }

    @include media-sm-and-down {
      background: $bg-light-gray;
      grid-template-columns: 0.1fr 0.1fr;
      grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr;
      grid-template-areas:
        'heading heading'
        'iconLoan iconWallet'
        'iconQr iconReload'
        'iconSube iconBills';

      .title {
        .headingTitle {
          font-size: 32px;
        }
      }

      .feature {
        &__img {
          max-width: 150px;
        }
        &__title {
          font-size: 0.75rem;
          text-decoration: none;
        }
      }
    }
  }

  .MnAr--RequestLoan {
    padding: 2.875rem 0 2.5rem;
    background: #fff;
    display: grid;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: 0.8fr 0.9fr;
    grid-template-rows: 0.5fr 0.5fr;
    justify-content: center;
    grid-template-areas:
      'loanImg loanTitle'
      'loanImg loanButton';

    .loanImg {
      grid-area: loanImg;
    }

    .loanTitle {
      grid-area: loanTitle;
      .loanContent {
        color: $primary-color;
        font-size: 50px;
      }
      span {
        color: $mid-gray;
        font-size: 18px;
      }
    }

    .loanButton {
      grid-area: loanButton;
      .btn.custom {
        border-radius: 7px;
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: white;
        }
      }
    }

    .imgDimensions {
      max-height: 620px;
    }

    @include media-sm-and-down {
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 0.5fr 0.1fr;
      grid-template-areas:
        'loanTitle'
        'loanImg'
        'loanButton';

      .loanTitle {
        justify-content: center;
        display: inline-grid;
        padding: 0px 34px;
        .loanContent {
          font-size: 32px;
        }
      }
      .imgDimensions {
        max-height: 500px;
        padding-top: 35px;
      }

      .loanButton {
        justify-content: center;
        display: inline-grid;
      }
    }
  }

  .MnAr--MoniCard {
    padding: 2.875rem 2.5rem;
    .mainContainer {
      display: grid;
      margin: 0 10rem 0 10rem;
      justify-content: center;
      grid-template-columns: 0.1fr 0.1fr;
      grid-template-rows: 0.1fr 0.8fr 0.7fr 0.1fr;
      grid-template-areas:
        'walletTitle walletTitle'
        'walletContent monicardContent'
        'newFeatureContent newFeatureContent'
        'buttonMoreContent buttonMoreContent';

      .walletTitle {
        grid-area: walletTitle;
        .moniTitle {
          padding: 0 15px;
          font-family: 'klein', sans-serif;
          text-transform: uppercase;
          font-size: 36px;
          color: #6ea485;
          background: #baf0d1;
          border-radius: 4px;
          width: fit-content;
        }
      }

      .walletContent {
        grid-area: walletContent;
        .walletImg {
          margin-top: 30px;
          max-height: 325px;
          display: flex;
          float: right;
        }
        .titleContent {
          color: $primary-color;
          font-size: 48px;
          width: 100%;
          display: flex;
          justify-content: right;
          padding-top: 10%;
        }
        .subtitleContent {
          color: $primary-color;
          width: 100%;
          font-size: 18px;
          line-height: 23px;
          span {
            color: #98aab4;
          }
        }
      }

      .monicardContent {
        grid-area: monicardContent;

        .titleContent {
          color: $primary-color;
          font-size: 48px;
          width: 100%;
          padding-left: 33px;
          margin-top: 0;
        }
        .subtitleContent {
          color: $primary-color;
          width: 100%;
          font-size: 18px;
          line-height: 23px;
          padding-left: 33px;
          span {
            color: #98aab4;
          }
        }
      }

      .newFeatureContent {
        grid-area: newFeatureContent;
        background: url('../img/bg-newFeatures-horizontal.svg') center no-repeat;
        background-size: 935px 248px;
      }

      .buttonMoreContent {
        grid-area: buttonMoreContent;
        display: flex;
        justify-content: center;
        .btn.custom {
          &:hover {
            color: white;
          }
          border-radius: 7px;
          color: $primary-color;
          border: 2px solid $primary-color;
        }
      }

      @include media-md-and-down {
        margin: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 0.1fr 0.5fr 0.5fr 0.9fr 0.1fr;
        grid-template-areas:
          'walletTitle'
          'walletContent'
          'monicardContent'
          'newFeatureContent'
          'buttonMoreContent';

        .walletTitle {
          display: flex;
          justify-content: center;
          text-align: center;
          .moniTitle {
            width: 55%;
            font-size: 36px;
            justify-content: center;
            padding: 0 8px;
          }
        }
        .walletContent {
          .walletImg {
            max-height: 264.49px;
            width: 100%;
          }
          .titleContent {
            text-align: center;
            justify-content: center;
            font-size: 33px;
          }
          .subtitleContent {
            text-align: center;
            font-size: 15px;
          }
        }

        .monicardContent {
          .monicardImg {
            max-height: 290px;
            width: 100%;
          }
          .titleContent {
            text-align: center;
            font-size: 33px;
          }
          .subtitleContent {
            text-align: center;
            font-size: 15px;
          }
        }

        .newFeatureContent {
          background: url('../img/bg-newFeatures-vertical.svg') center no-repeat;
        }
      }
    }
  }

  .MnAr--PayInvoices {
    padding: 2.5rem 0 0 0;
    background: #f2f2f2;
    .mainContainer {
      display: grid;
      justify-content: center;
      grid-template-columns: 0.3fr 0.3fr 0.3fr;
      grid-template-rows: 0.1fr 0.5fr 1.2fr;
      grid-template-areas:
        'paymentsTitle paymentsTitle paymentsTitle'
        'invoicesContent phoneReload subeReload'
        'payments payments payments';

      .paymentsTitle {
        grid-area: paymentsTitle;
        justify-content: center;
        display: grid;
        margin-bottom: 5%;
        .titleContent {
          justify-content: center;
          width: fit-content;
          background: #fad0d0;
          font-family: 'klein', sans-serif;
          font-size: 36px;
          text-transform: uppercase;
          color: #c89696;
          padding: 0px 15px;
          border-radius: 4px;
        }
      }
      .invoicesContent {
        grid-area: invoicesContent;
        display: flex;
        justify-content: center;
        position: relative;
        .buttonContent {
          position: absolute;
          bottom: 20%;
          .btn.custom {
            background: $bg-green;
            color: #fff;
            border: 2px solid $bg-green;
          }
        }
      }
      .phoneReloadContent {
        grid-area: phoneReload;
        display: flex;
        justify-content: center;
        position: relative;
        .buttonContent {
          position: absolute;
          bottom: 20%;
          .btn.custom {
            background: #ffe900;
            color: #3f5366;
            border: 2px solid #ffe900;
          }
        }
      }
      .subeReloadContent {
        grid-area: subeReload;
        display: flex;
        justify-content: center;
        position: relative;
        .buttonContent {
          position: absolute;
          bottom: 20%;
          .btn.custom {
            background: $dark-blue;
            color: #fff;
            border: 2px solid $dark-blue;
          }
        }
      }
      .paymentsContent {
        grid-area: payments;
        background: url('../img/icon-paymentOptions-horizontal.svg') center
          no-repeat;
      }

      @include media-md-and-down {
        margin: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 0.1fr 2fr 2fr 2fr 4fr;
        grid-template-areas:
          'paymentsTitle'
          'invoicesContent'
          'phoneReload'
          'subeReload'
          'payments';

        .imgStyle {
          height: 190px;
        }
        .buttonContent {
          top: 50%;
          .btn.custom {
            min-width: 188px;
          }
        }
        .paymentsContent {
          background: url('../img/icon-paymentOptions-vertical.svg') center
            no-repeat;
        }
      }
    }
    .footer {
      height: 661px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      position: relative;
      overflow: hidden;

      &__content {
        width: 100%;
        height: 100%;
        background: url("../img/bg-loanFooter-vertical.svg") no-repeat;
        background-size: cover;
        background-position: top center;
        position: absolute;
        top: 0;
        max-width: 428px;
        margin: auto;

        @include media-sm-and-up {
          background: url("../img/bg-loanFooter-horizontal.svg") no-repeat;
          background-size: cover;
          background-position: top center;
          max-width: 2000px;
        }
      }

      @include media-xxs-and-up {
        height: 760px;
      }

      @include media-sm-and-up {
        height: 540px;
      }

      @include media-lg-and-up {
        height: 740px;
      }
    }
  }

  .MnAr--Security {
    padding: 6.375rem 0;

    .securityContainer {
      display: grid;
      justify-content: center;
      column-gap: 94px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'securityImg securityText'
        'securityLogoContent securityLogoContent';

      @include media-sm-and-down {
        column-gap: 24px;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: 1fr auto;
        grid-template-areas:
          'securityText securityImg'
          'securityLogoContent securityLogoContent';
      }
    }

    .securityImg {
      grid-area: securityImg;
      display: flex;
      align-items: center;
      justify-content: end;

      @include media-sm-and-down {
        justify-content: start;
        overflow-x: hidden !important;
      }
    }

    .securityText {
      grid-area: securityText;
      color: $text-color;
      max-width: 300px;
      font-size: 18px;
      line-height: 23px;

      .security__heading {
        font-weight: 900;
        font-size: 34px;
        line-height: 32px;

        @include media-sm-and-down {
          font-size: 23px;
          line-height: 23px;
        }
      }

      .security__subheading {
        font-weight: 400;
      }

      .security__link {
        font-weight: 700;

        a {
          color: $text-color;
          text-decoration: underline;
        }
      }

      .btn.custom {
        width: 100%;
        min-width: 174px;
        max-width: 261px;
        border-radius: 7px;
        color: $primary-color;
        border: 2px solid $primary-color;
        margin: 19px 0;
        &:hover {
          color: white;
        }
      }

      @include media-sm-and-down {
        max-width: initial;
        font-size: 14px;
        line-height: 18px;
        padding-left: 48px;

        .btn.custom {
          margin: 14px 0;
        }
      }
    }

    .securityLogoContent {
      grid-area: securityLogoContent;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      padding: 84px 0;
      gap: 3rem;

      .security__logo {
        mix-blend-mode: normal;
        opacity: 0.49;

        @include media-sm-and-down {
          opacity: 0.57;
        }
      }

      @include media-sm-and-down {
        justify-content: start;
        flex-wrap: wrap;
        padding: 16px 48px;
        gap: 1rem 2rem;
      }
    }

    @include media-sm-and-down {
      padding: 4.375rem 0;
    }
  }
}
